'use strict';

/**
 * Library for the mandarin stone search
 */
class msSearch {

    /**
     * Triggered on page load
     */
    constructor()
    {

        // set ajax url
        this.ajax_url = '/wp/wp-admin/admin-ajax.php';

        // setup variable to indicate that search box is focused in
        this.is_search_box_focused = false;

        // setup variable to store total items count
        this.total_items_count = 0;

        // setup variable to hold hard searchable criteria
        this.hard_searchable_criteria = false;

        // setup variable to hold options
        this.options = [];

        // setup array to store search results
        this.search_results = {
            'categories': [],
            'filter_results': [],
            'products': [],
            'pages': [],
            'popular_suggestions': [],
        };

        // do an ajax request to retrieve and save the hard searchable criteria
        this.get_hard_searchable_criteria_data();

        // bind to elements
        this.bind_elements();

        // event listeners
        this.listeners();

        // open search on load
        this.open_search();

    }

    /**
     * Bind to search elements
     */
    bind_elements()
    {
        this.search_element = $('.searchanise-input:visible');
    }

    /**
     * Event listeners
     */
    listeners()
    {

        // instantiate a copy of self when out of scope
        let that = this;

        // listen for clicks on search trigger
        $(document.body).on('click', '.js-search-trigger', function () {
            that.open_search();
        });

        // close on escape
        document.addEventListener('keydown', function ( event) {
            if (event.key == 'Escape') {
                that.close_search();
            }
        });

        // close
        $(document.body).on('click', '.js-close-search', function () {
            that.close_search();
        });

        // listen for clicks on suggestions
        $(document.body).on('click', '.search-suggestions li', function () {
            let text = $(this).text();
            that.search_element.val(text).trigger('keyup');
        });

        // detect key press
        $(document.body).on('keyup', this.search_element, function (e) {
            if (((e.which <= 90 && e.which >= 48) || (e.which = 229)) && that.search_element.val().length > 0) {
                that.perform_search();
            }

            // if enter key is pressed
            if (e.keyCode === 13) {
                setTimeout(() => {
                    window.location.href = $('.search-results-wrapper .view-all-results').attr('href');
                }, 100);
            }
        });

        // click result
        $(document.body).on('click', '.ms-search-results', function (e) {

            // track event
            $(document.body).trigger('ud-event', 'click_search_result', e);

        });

    }

    /**
     * Get search input value
     */
    get_search_input_value()
    {
        return this.search_element.val();
    }

    /**
     * Perform a search request
     */
    perform_search()
    {

        // first perform search on hard criteria as this is fast
        this.perform_search_on_hard_search_criteria();

        // then perform searchanise search
        this.perform_searchanise_search();

    }

    /**
     * Get api vars
     */
    get_api_config_vars()
    {

        let config_vars = {
            'api_key': '0J1M9h4r8T',
            'q': this.get_search_input_value(),
            'restrictBy': {
                'visibility': 'visible|catalog|search',
                'status': 'publish',
                'usergroup_ids': 'guest',
            },
            'maxResults': '3',
            'startIndex': '0',
            'items': 'true',
            'pages': 'true',
            'facets': 'false',
            'categories': 'true',
            'suggestions': 'true',
            'vendors': 'false',
            'tags': 'false',
            'pageStartIndex': '0',
            'pagesMaxResults': '3',
            'categoryStartIndex': '0',
            'categoriesMaxResults': '3',
            'suggestionsMaxResults': '4',
            'vendorsMaxResults': '3',
            'tagsMaxResults': '3',
            'output': 'jsonp',
            // 'SuggestSingleWords': 'true',
            // 'SuggestGeneratedPhrases': 'false',
        };

        return $.param(config_vars);

    }

    /**
     * Do an ajax request to retrieve and save the hard searchable criteria
     */
    get_hard_searchable_criteria_data()
    {

        // instantiate a copy of self when out of scope
        let that = this;

        // do ajax request to `wp_ajax_ms_ajax_hard_searchable_criteria`
        $.ajax({
            url: window.get_ajax_url() + '?action=ms_ajax_hard_searchable_criteria',
            dataType: 'json',
            type: 'GET',
            success: function (data) {
                // store search criteria as array to local variable
                that.hard_searchable_criteria = data;

                // store search options options
                if (typeof data['options'] !== 'undefined') {
                    that.options = data['options'];
                }

            },
        });

    }

    /**
     * Get stop words
     */
    get_stop_words()
    {
        if (typeof this.options['stop_words'] !== 'undefined' && this.options['stop_words'].length) {
            return this.options['stop_words'];
        }
    }

    /**
     * Perform a search on hard criteria
     */
    perform_search_on_hard_search_criteria()
    {

        // instantiate a copy of self when out of scope
        let that = this;

        // get results for search
        let filters_results = that.perform_search_on_hard_results(
            this.get_search_input_value(),
            'filters'
        );

        // clear existing result arrays
        that.search_results['filter_results'] = [];

        // if a result exists then loop through it and add each item to the search results
        if (filters_results && filters_results.length) {
            filters_results.forEach(function (item) {

                // setup variables to store search item result
                let tax_name = item.tax_name;
                let name = item.name;
                let title = '<span class="tax">' + tax_name + '</span>: ' + name;

                // if tax name is an array then add each array item into the search result
                if (Array.isArray(tax_name)) {
                    title = [];
                    Object.keys(tax_name).forEach(function (key) {
                        title.push('<span class="tax">' + tax_name[key] + '</span>: ' + name[key]);
                    });
                    title = title.join(',&nbsp;');
                }

                // populate filter result
                that.search_results['filter_results'].push({
                    'image' : false,
                    'title': title,
                    'link': item.url,
                });
            });
        }

        // render html output of results
        this.render_results();

    }

    /**
     * Remove stop words from given string
     */
    remove_stop_words_from_string(s)
    {
        // get the stop words
        let stop_words = this.get_stop_words();

        // return string if no stop words found
        if (!stop_words || !stop_words.length) {
            return s;
        }

        // split the search string into an array of words
        let words = s.split(' ');

        // filter out the stop words
        let filtered_words = words.filter(function (word) {
            return !stop_words.includes(word);
        });

        // join the remaining words back into a string
        return filtered_words.join(' ');
    }

    /**
     * Generate a multi term result item
     */
    generate_multi_term_result(results)
    {
        // only perform if there are 2 or more results
        if (!results || results.length < 2) {
            return;
        }

        // setup variables to store the multiple term data
        let url = [];
        let label = [];
        let name = [];
        let tax_name = [];

        // loop through each result
        Object.keys(results).forEach(function (key) {
            let result_d =  results[key];

            // if the first key then we use the full url as the base of the url
            if (key > 0) {
                url.push(result_d.slug);
            } else {
                url.push(result_d.url.replace(/\/$/, ''));
            }

            // populate other parts of the variables
            label.push(result_d.name);
            name.push(result_d.name);
            tax_name.push(result_d.tax_name);
        });

        // combine slugs and base url
        let url_str = url.join('/') + '/';

        // return multi term item data
        return {
            'name': name,
            'slug': '',
            'tax_name': tax_name,
            'tax_slug': '',
            'term_id': 0,
            'url': url_str,
        }
    }

    /**
     * Perform a search on hard results
     */
    perform_search_on_hard_results(s, dict)
    {

        // set results array
        let results = [];

        // set up variable to store potential multi term matches
        let multi_term_match = [];

        // if this dictionary doesn't exist return false
        if (this.hard_searchable_criteria[dict] == null) {
            return;
        }

        // convert search string to lower case
        s = s.toLowerCase().trim();

        // remove any stop words
        s = this.remove_stop_words_from_string(s);

        let criteria = this.hard_searchable_criteria[dict];
        Object.keys(criteria).forEach(function (key) {
            if(typeof criteria[key]['terms'] !== 'undefined')
            {
                let terms = criteria[key]['terms'];
                Object.keys(terms).forEach(function (t_key) {
                    let name = terms[t_key]['name'];
                    if (name.toLowerCase().indexOf(s) !== -1) {
                        let term_data = terms[t_key];
                        term_data['tax_slug'] = key;
                        term_data['tax_name'] = criteria[key]['attribute_label'];
                        results.push(term_data);
                    }


                    // look for multi-term matches
                    if (s.includes(' ')) {
                        let pm_items = s.split(' ');
                        Object.keys(pm_items).forEach(function (pm_key) {
                            let pm_value = pm_items[pm_key];
                            if (name.toLowerCase() == pm_value) {

                                let term_data = terms[t_key];
                                term_data['tax_slug'] = key;
                                term_data['tax_name'] = criteria[key]['attribute_label'];

                                multi_term_match.push(term_data);
                            }
                        });
                    }



                });
            }
        });

        // if we were able to capture multi term results then generate a single item for it
        let multi_term_result = this.generate_multi_term_result(multi_term_match);
        if (multi_term_result) {
            results.push(multi_term_result);
        }

        // sort by if starts with string
        results.sort(function (x, y) {
            return x['name'].toLowerCase().startsWith(s)
                ? -1
                : y['name'].toLowerCase().startsWith(s) ? 1 : 0;
        });

        // sort by if is exact match
        results.sort(function (x, y) {
            return x['name'].toLowerCase() == s
                ? -1
                : y['name'].toLowerCase() == s ? 1 : 0;
        });

        // trim results to 3 if any greater
        if (results.length > 3) {
            results.length = 3;
        }

        return results;

    }

    /**
     * Perform a searchanise search
     */
    perform_searchanise_search()
    {

        // instantiate a copy of self when out of scope
        let that = this;

        $.ajax({
            dataType: 'json',
            url: 'https://www.searchanise.com/getwidgets?' +
                this.get_api_config_vars(),
            success: function (data) {

                // update total found items
                that.total_items_count = data.totalItems;

                // clear existing result arrays
                that.search_results['products'] = [];
                that.search_results['popular_suggestions'] = [];
                that.search_results['pages'] = [];
                that.search_results['categories'] = [];

                // add products to results
                if (typeof data.items !== 'undefined') {
                    data.items.forEach(function (item) {
                        that.search_results['products'].push({
                            'image': item.image_link,
                            'title': item.title,
                            'link': item.link,
                        });
                    });
                }

                // add categories to results
                if (typeof data.categories !== 'undefined') {
                    data.categories.forEach(function (item) {
                        that.search_results['categories'].push({
                            'image': false,
                            'title': item.title,
                            'link': item.link,
                        });
                    });
                }

                // add pages to results
                if (typeof data.pages !== 'undefined') {
                    data.pages.forEach(function (item) {
                        that.search_results['pages'].push({
                            'image': false,
                            'title': item.title,
                            'link': item.link,
                        });
                    });
                }

                // add suggestions to results
                if (typeof data.suggestions !== 'undefined') {
                    data.suggestions.forEach(function (item) {
                        that.search_results['popular_suggestions'].push({
                            'image': false,
                            'title': item,
                            'link': '/collections/all-products/?search=' +
                                encodeURIComponent(
                                    item
                                ),
                        });
                    });
                }

                // render html output of results
                that.render_results();

            },
        });

    }

    /**
     * Open search box
     */
    open_search()
    {

        // variable to indicate that search box is focused in
        this.is_search_box_focused = true;

        // add class to body to indicate search box is focused in
        $(document.body).addClass('search-box-focused');

        let that = this;

        // Reveal results if input has value
        if(that.search_element.val().length > 0) {
            $('.search-results-wrapper').addClass('active');
        }

        // focus inside search input on desktop
        if ($(window).width() > 1023.98) {
            setTimeout(function () {
                that.search_element.focus();
            }, 100)
        }

        // track event
        $(document.body).trigger('ud-event', 'open_product_search');

    }

    /**
     * Render the search results
     */
    render_results()
    {

        // get search results
        let data = this.search_results;

        // setup html element with container
        let html = '';
        // console.log(data);

        // loop through each search result group
        for (let key in data) {
            // get title of search result group
            let filter_group_title = key.replace('_', ' ');

            // get search result items in group
            let result_items = data[key];

            // if has results
            if (result_items && result_items.length) {
                // wrap search items in ul
                html += '<span class="search-group-title nav-pre-title d-block mb-0_75">' + filter_group_title + '</span>';
                html += '<ul class="' + key + ' mb-2">';

                // loop through each result item
                for (let key in result_items) {
                    let d = result_items[key];

                    // add url
                    html += '<li><a class="search-result-item d-flex" href="' + d.link + '">';

                    // if has an image url then insert image
                    if (d.image) {
                        html += '<img src="' + d.image + '" width="45px" height="45px" class="mr-1">';
                    }

                    // search item title
                    html += d.title + '</a></li>';
                }

                html += '</ul>';
            }
        }

        // update view all results button
        if (this.total_items_count) {
            html += '<a class="ms-underline-button view-all-results" href="/collections/all-products/?search=' +
                encodeURIComponent(this.get_search_input_value()) +
                '">View all ' + this.total_items_count + ' results</a>';
        } else {
            html += '<span class="search-group-title nav-pre-title d-block mb-0_75">Products</span>' +
                '<ul><li><a href="#">No results found</a></li></ul>' +
                '<a class="ms-underline-button view-all-results d-none" href="/collections/all-products/?search=' +
                encodeURIComponent(this.get_search_input_value()) +
                '">No results</a>';
        }

        // update search results element html
        this.update_search_results_html(html);

    }

    /**
     * Update results box's html if it exists else create the element
     */
    update_search_results_html(html)
    {
        $('.search-results-wrapper').addClass('active');
        if ($('.ms-search-results').length) {
            $('.ms-search-results').html(html);
        } else {
            let ms_search_results_elem = document.createElement('div');
            ms_search_results_elem.innerHTML = html;
            ms_search_results_elem.setAttribute('class', 'ms-search-results pb-2');
            $('.search-results').append(ms_search_results_elem);
        }
    }

    /**
     * Close search box
     */
    close_search()
    {

        // reset indicator variable to false
        this.is_search_box_focused = false;

        // remove class as search box is now closed
        $(document.body).removeClass('search-box-focused');

    }

}

/**
 * When document is ready initialize our search class
 */
$(document).ready(function () {
    new msSearch();
});
